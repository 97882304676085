import React, { useState } from "react"

import "./Belt.scss"
import Card, { BorderedCard } from "../../../components/Card/Card"
import Button from "../../../components/Button"
import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMount } from "react-use"
import Draggable from "react-draggable"
import clsx from "clsx"
import useGameSize from "../../../hooks/screen/useGameSize"
import { getDragPositionFromEvent } from "../../../lib/DragPosition"
import { useTranslation } from "react-i18next"

const Belt = ({ answers, onDragging, onDragged, answeredCount, allCount }) => {
  const { t } = useTranslation("exercises/truck")
  const { isVertical } = useGameSize()
  const [page, setPage] = useState(-1)
  const [maxPage, setMaxPage] = useState(0)
  const [transformPosition, setTransformPosition] = useState("0 0")

  useMount(() => {
    setTimeout(() => setPage(0), 1000)
    setMaxPage(Math.floor((answers.length - 1) / (isVertical ? 2 : 3)))
  })

  const onStartDragging$ = (answer) => (e) => {
    const bounds = e.target.getBoundingClientRect()
    const position = getDragPositionFromEvent(e)
    setTransformPosition(`${position[0] - bounds.x}px ${position[1] - bounds.y}px`)
    onDragging(...position, answer)
  }

  const onDragging$ = (answer) => (e) => {
    onDragging(...getDragPositionFromEvent(e), answer)
  }

  const onFinishDragging$ = (answer) => (e) => {
    onDragged(...getDragPositionFromEvent(e), answer)
  }

  return (
    <div className="Belt">
      <div className="conveyor">
        <div className="background" />
        <div className="answers" style={{ left: `${-page * 100}%` }}>
          {answers.map((answer) => {
            const { id, content, answered, correct } = answer

            return (
              <div className="answer-container" key={id}>
                <Draggable
                  position={answered ? {} : { x: 0, y: 0 }}
                  // onStop={answerDraggedAction(answer)}
                  // disabled={!answer.active || disabled}
                  onStart={onStartDragging$(answer)}
                  onDrag={onDragging$(answer)}
                  onStop={onFinishDragging$(answer)}
                  disabled={answered}
                >
                  <div className={clsx("drag-handle", { finished: answered })}>
                    <BorderedCard
                      isDraggable={true}
                      color={Card.COLORS.CLICKABLE}
                      style={{ transformOrigin: transformPosition }}
                      className="answer"
                      isOpaque={answered}
                    >
                      {content}
                    </BorderedCard>
                  </div>
                </Draggable>
                <BorderedCard
                  className="answer-loaded"
                  color={correct ? Card.COLORS.SUCCESS : Card.COLORS.FAILURE}
                  isOpaque={!answered}
                >
                  <div className="loaded-text">
                    {correct ? t("package_loaded") : t("package_rejected")}
                  </div>
                </BorderedCard>
              </div>
            )
          })}
        </div>
      </div>
      <Card className="count">{t("loaded_count", { answeredCount, allCount })}</Card>
      <div className="controls">
        <Button disabled={page <= 0} onClick={() => setPage(page - 1)}>
          <FontAwesomeIcon className="icon" icon={faAnglesLeft} /> {t("previous")}
        </Button>
        <Button disabled={page >= maxPage} onClick={() => setPage(page + 1)}>
          {t("next")} <FontAwesomeIcon className="icon" icon={faAnglesRight} />
        </Button>
      </div>
    </div>
  )
}

export default Belt
