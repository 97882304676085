import CleaningExercise from "../components/CleaningExercise"
import PuzzleExercise from "../puzzle/PuzzleExercise"
import TrueFalseExercise from "../true_false/TrueFalseExercise"
import BubblesExercise from "../bubbles/BubblesExercise"
import ConversationsExercise from "../conversations/ConversationsExercise"
import DistributionExercise from "../distribution/DistributionExercise"
import ValuesExercise from "../values/ValuesExercise"
import TruckExercise from "../truck/TruckExercise"

const EXERCISE_COMPONENTS = {
  cleaning: CleaningExercise,
  puzzle: PuzzleExercise,
  true_false: TrueFalseExercise,
  bubbles: BubblesExercise,
  conversations: ConversationsExercise,
  distribution: DistributionExercise,
  values: ValuesExercise,
  truck: TruckExercise,
}

export default EXERCISE_COMPONENTS
