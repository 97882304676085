import React, { Component } from "react"
import Card from "components/Card/Card"
import anime from "animejs"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ReactComponent as ParachuteSVG } from "../img/parachute.svg"

import "./ParachutesCard.scss"
import PlayArea from "../../../../lib/PlayArea"

export const STATES = {
  MOVING: 0,
  PAUSED: 1,
}

export default class ParachutesCard extends Component {
  static propTypes = {
    count: PropTypes.number,
    answer: PropTypes.object,
    fallDurationS: PropTypes.number,
    freezeParachute: PropTypes.bool,

    onFallFinished: PropTypes.func,

    innerRef: PropTypes.object,
  }

  animation

  componentDidMount() {
    this.restartAnimation()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.count !== this.props.count) {
      this.animation.restart()
    }

    if (prevProps.answer.active !== this.props.answer.active) {
      this.stateChanged(this.props.answer.active)
    }
  }

  stateChanged = (isActive) => {
    if (isActive) {
      this.animation.restart()
    } else {
      this.animation.pause()
    }
  }

  restartAnimation = () => {
    const { fallDurationS, innerRef, onFallFinished, freezeParachute } = this.props

    let translateY
    if (freezeParachute) {
      translateY = PlayArea.isVertical() ? "60rem" : "38rem"
    } else {
      translateY = PlayArea.isVertical() ? "112rem" : "87rem"
    }

    this.animation = anime({
      targets: innerRef.current,
      duration: freezeParachute ? 500 : fallDurationS * 1000,
      translateY,
      easing: freezeParachute
        ? "cubicBezier(0.645, 0.045, 0.355, 1.000)"
        : "cubicBezier(.2, .5, .9, .1)",
      complete: freezeParachute ? undefined : onFallFinished,
    })
  }

  render() {
    const { answer, innerRef } = this.props

    return (
      <div className="ParachutesCard">
        <Card className={classNames({ active: answer.active })} innerRef={innerRef}>
          <ParachuteSVG className="parachute" />
          {answer.content}
        </Card>
      </div>
    )
  }
}
