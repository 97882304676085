import { useWindowSize } from "react-use"
import { useCallback, useEffect, useMemo, useState } from "react"
import { singletonHook } from "react-singleton-hook"
import PlayArea from "../../lib/PlayArea"

export interface GameSizeI {
  width: number
  height: number
  widthEms: number
  proportions: number
  font: number
  isVertical: boolean
}

const SPRINT_BAR_HEIGHT = 40

export const MAX_WIDER = 1600
export const MAX_NARROWER = 1000

const INITIAL_STATE = {
  width: 0,
  height: 0,
  widthEms: 0,
  proportions: 0,
  font: 0,
  isVertical: false,
}

const useGameSize = singletonHook(INITIAL_STATE, (): GameSizeI => {
  const { width, height: windowHeight } = useWindowSize()
  const height = useMemo(() => {
    return windowHeight - SPRINT_BAR_HEIGHT
  }, [windowHeight])
  const [size, setSize] = useState<GameSizeI>(INITIAL_STATE)

  const resize = useCallback(
    (proportions: number) => {
      let newWidth = width
      let newHeight = height

      if (newWidth > newHeight * proportions) {
        newWidth = newHeight * proportions
      } else {
        newHeight = newWidth / proportions
      }

      setSize({
        width: newWidth,
        height: newHeight,
        widthEms: PlayArea.widthInEms(),
        proportions: newWidth > newHeight ? newHeight / MAX_NARROWER : newWidth / MAX_NARROWER,
        font: newWidth / PlayArea.widthInEms(),
        isVertical: PlayArea.isVertical(),
      })
    },
    [width, height]
  )

  useEffect(() => {
    if (PlayArea.isVertical()) {
      resize(10 / 16)
    } else {
      resize(16 / 10)
    }
  }, [resize])

  return size
})

export default useGameSize
