import React, { useCallback, useEffect } from "react"
import Card from "components/Card/Card"
import "./DistributionExercise.scss"
import Button from "../../components/Button"
import { ANSWERS_STATES as AS } from "../../@exercises/Exercise"
import Element from "../../components/Element"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { range, size } from "lodash"
import { useMap } from "react-use"

const IMAGES = {
  1: require("images/smiles/huge-frown.svg").default,
  2: require("images/smiles/frown.svg").default,
  3: require("images/smiles/meh.svg").default,
  4: require("images/smiles/smile.svg").default,
  5: require("images/smiles/huge-smile.svg").default,
}

const Answer = ({ content, select, current }) => {
  const value = current?.value

  return (
    <div className="Answer">
      <Card className="content">{content}</Card>
      <div className="scale">
        {range(1, 6).map((index) => (
          <Button
            key={index}
            className={clsx(`s${index}`, {
              selected: value === index,
              unselected: value && value !== index,
            })}
            onClick={() => select(index)}
          >
            <img src={IMAGES[index]} alt="" />
            <span>{index}</span>
          </Button>
        ))}
      </div>
    </div>
  )
}

const DistributionExercise = ({ state, answers, question, inAnswersState, answerChosen }) => {
  const { t } = useTranslation("common")
  const [selections, { set, reset }] = useMap({})

  useEffect(() => {
    reset()
  }, [reset, question])

  const selectAnswers = useCallback(() => {
    const answersInfo = Object.values(selections)
    const sum = answersInfo.reduce((prev, current) => {
      return current.value + prev
    }, 0)
    let percentageSum = 0
    answersInfo.forEach(({ answer, value }, index) => {
      let percentage
      if (index < answersInfo.length - 1) {
        percentage = Math.round((value * 10000) / sum)
        percentageSum += percentage
      } else {
        percentage = 10000 - percentageSum
      }
      percentage = (percentage / 100).toFixed(2)
      answerChosen(answer, { other: { value, percentage } })
    })
  }, [answerChosen, selections])

  return (
    <div className="DistributionExercise">
      <Element active={inAnswersState([AS.APPEARING, AS.ACTIVE])}>
        <div className="answers scrollable">
          {answers.map((answer) => (
            <Answer
              key={answer.id}
              content={answer.content}
              select={(value) => set(answer.id, { answer, value })}
              current={selections[answer.id]}
            />
          ))}
        </div>
        <Button big={true} onClick={selectAnswers} disabled={size(selections) < answers.length}>
          {t("continue")}
        </Button>
      </Element>
    </div>
  )
}

export default DistributionExercise
