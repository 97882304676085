import "./FatalError.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons"

export default function FatalError({ error }) {
  return (
    <div className="FatalError">
      <div className="message">
        <FontAwesomeIcon className="icon" icon={faTriangleExclamation} />
        <p>{error}</p>
      </div>
    </div>
  )
}
