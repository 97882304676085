import React, { useCallback, useEffect, useRef, useState } from "react"
import VotingExercise from "../VotingExercise"
import { withTranslation } from "react-i18next"
import Button from "../../../components/Button"
import { chain, sum } from "lodash"
import classNames from "classnames"
import { ReactComponent as Frequency6Img } from "../images/frequency/7.svg"
import { ReactComponent as Frequency5Img } from "../images/frequency/6.svg"
import { ReactComponent as Frequency4Img } from "../images/frequency/5.svg"
import { ReactComponent as Frequency3Img } from "../images/frequency/3.svg"
import { ReactComponent as Frequency2Img } from "../images/frequency/2.svg"
import { ReactComponent as Frequency1Img } from "../images/frequency/1.svg"

import "./SzymonVotingExercise.scss"
import Card from "../../../components/Card/Card"
import { useList, useMap } from "react-use"
import AnimatedElement from "../../../components/AnimatedElement/AnimatedElement"
import validate from "validate.js"
import axios from "axios"
import { SERVER_ADDRESS } from "app/Sprint"
import clsx from "clsx"

const FREQUENCY_DESCRIPTION = {
  6: "Podpisuję się rękami i nogami",
  5: "W dużym stopniu tak właśnie jest",
  4: "Raczej jestem na tak",
  3: "Raczej jestem na nie",
  2: "To mnie właściwie nie dotyczy",
  1: "To jest absolutnie „nie moje”",
}

const IMAGES = {
  6: Frequency6Img,
  5: Frequency5Img,
  4: Frequency4Img,
  3: Frequency3Img,
  2: Frequency2Img,
  1: Frequency1Img,
}

const VOTE_MAP = { 6: -9, 5: -5, 4: -2, 3: 2, 2: 5, 1: 9 }
const VOTE_MAP_REVERSED = { 6: 9, 5: 5, 4: 2, 3: -2, 2: -5, 1: -9 }

const processVote$ = (correctVoteMap, incorrectVoteMap) => (vote, answer) => {
  if (answer.correct) return correctVoteMap[vote]
  else return incorrectVoteMap[vote]
}

const SzymonVotingExercise = ({
  exerciseId,
  className,
  answersComponent = SzymonVotingExerciseAnswers,
  ScaleSummaryComponent = ScaleSummary,
  QuestionSummaryComponent = QuestionSummary,
  ExerciseSummaryComponent = ExerciseSummary,
  voteMapCorrect = VOTE_MAP,
  voteMapIncorrect = VOTE_MAP_REVERSED,
  ...other
}) => {
  const [questionSummary, { set: setQuestionSummary, setAll: setAllQuestionSummary }] = useMap({
    visible: false,
    title: "",
    description: "",
    points: "",
    pointsScale: [0, 0],
    comment: "",
  })
  const [showExerciseSummary, setShowExerciseSummary] = useState(false)
  const [exerciseSummary, { push: addToExerciseSummary }] = useList([])
  const [isLastQuestion, setIsLastQuestion] = useState(false)
  const onNext = useRef(() => {})

  const onQuestionFinished = useCallback(
    (resolve, question, results, isLastQuestion = false) => {
      setIsLastQuestion(isLastQuestion)
      const result = sum(results)
      const { description, labels, points } = question.parameters
      let comment, resultName
      if (points) {
        for (let pointsInfo of points) {
          const { from, to, name, description: pointsDescription } = pointsInfo
          if (result >= from && result <= to) {
            resultName = name
            comment = pointsDescription
            break
          }
        }
      }

      const currentQuestionSummary = {
        id: question.id,
        title: question.content,
        description,
        points: result,
        pointsScale: points ? Math.abs(points[0].from) : 0,
        pointsLabels: labels,
        resultName,
        comment,
      }
      addToExerciseSummary(currentQuestionSummary)

      if (!points) {
        resolve()
      } else {
        setAllQuestionSummary({
          visible: true,
          ...currentQuestionSummary,
        })

        onNext.current = resolve
      }
    },
    [setAllQuestionSummary, addToExerciseSummary]
  )

  const nextQuestion = useCallback(() => {
    setQuestionSummary("visible", false)
    setTimeout(() => onNext.current(), 600)
  }, [setQuestionSummary])

  const onExerciseFinished = useCallback(
    (resolve) => {
      if (!ExerciseSummaryComponent) {
        resolve()
      } else {
        setShowExerciseSummary(true)
        onNext.current = resolve
      }
    },
    [ExerciseSummaryComponent]
  )

  const finishExercise = useCallback(() => {
    setShowExerciseSummary(false)
    setTimeout(() => onNext.current(), 600)
  }, [setShowExerciseSummary])

  return (
    <div className={clsx(className, "SzymonVotingExercise")}>
      <VotingExercise
        answersComponent={answersComponent}
        processVote={processVote$(voteMapCorrect, voteMapIncorrect)}
        onQuestionFinished={onQuestionFinished}
        onExerciseFinished={onExerciseFinished}
        {...other}
      />
      <QuestionSummaryComponent
        onNext={nextQuestion}
        isLastQuestion={isLastQuestion}
        ScaleSummaryComponent={ScaleSummaryComponent}
        parameters={other.parameters}
        {...questionSummary}
      />
      {ExerciseSummaryComponent && (
        <ExerciseSummaryComponent
          exerciseId={exerciseId}
          visible={showExerciseSummary}
          onNext={finishExercise}
          summary={exerciseSummary}
          ScaleSummaryComponent={ScaleSummaryComponent}
        />
      )}
    </div>
  )
}

const QuestionSummary = ({
  visible,
  title,
  description,
  points,
  pointsScale,
  pointsLabels,
  resultName,
  comment,
  isLastQuestion,
  onNext,
  ScaleSummaryComponent,
}) => {
  return (
    <AnimatedElement visible={visible} className="QuestionSummary">
      <Card>
        <h1 className="title">{title}</h1>
        <p>{description}</p>
        <ScaleSummaryComponent
          points={points}
          pointsScale={pointsScale}
          pointsLabels={pointsLabels}
        />
      </Card>
      <Card>
        <h1 className="result-name">
          Twój wynik: <strong>{resultName}</strong>
        </h1>
        <p className="result-comment">{comment}</p>
      </Card>
      <Button big onClick={onNext}>
        {isLastQuestion ? "Przejdź do podsumowania badania" : "Przejdź do dalszej części badania"}
      </Button>
    </AnimatedElement>
  )
}

const ScaleSummary = ({ points, pointsScale, pointsLabels }) => {
  const [position, setPosition] = useState(50)
  const timeoutRef = useRef(null)

  useEffect(() => {
    const log = Math.log1p
    const modifier = 0.1

    const logScale = log(pointsScale * modifier)
    let logPoints = 0
    if (points > 0) {
      logPoints = log(points * modifier)
    } else if (points < 0) {
      logPoints = -log(-points * modifier)
    }

    timeoutRef.current = setTimeout(
      () => setPosition(((logPoints + logScale) / (2 * logScale)) * 100),
      1000
    )

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [points, pointsScale])

  return (
    <div className="ScaleSummary">
      <div className="left-label">{pointsLabels[0]}</div>
      <div className="right-label">{pointsLabels[1]}</div>
      <div className="slider" style={{ left: `${position}%` }} />
    </div>
  )
}

const MAX_FAILURES = 3

const ExerciseSummary = ({ exerciseId, visible, onNext, summary, ScaleSummaryComponent }) => {
  const [email, setEmail] = useState("")
  const [error, { set: setError, setAll: setAllError }] = useMap({
    shown: false,
    message: "",
  })
  const [sending, setSending] = useState(false)
  const failCount = useRef(0)

  const innerOnNext = useCallback(() => {
    let validationError

    if (email) {
      validationError = validate(
        { email },
        {
          email: {
            email: {
              message: "^Podaj prawidłowy adres e-mail.",
            },
          },
        }
      )

      if (validationError) {
        setAllError({ shown: true, message: validationError["email"][0] })
      } else {
        setSending(true)
        setError("shown", false)
        axios({
          method: "POST",
          url: SERVER_ADDRESS + "szymon/ikr",
          data: {
            email,
            exerciseId,
            results: chain(summary).keyBy("id").mapValues("points").value(),
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(() => {
            setSending(false)
            onNext()
          })
          .catch(() => {
            setSending(false)
            failCount.current += 1
            if (failCount.current <= MAX_FAILURES) {
              setAllError({
                shown: true,
                message:
                  "Wystąpił problem podczas wysyłki maila - upewnij się, że podałeś prawidłowy adres.",
              })
            } else {
              onNext()
            }
          })
      }
    } else {
      onNext()
    }
  }, [onNext, setError, setAllError, email, exerciseId, summary])

  return (
    <AnimatedElement visible={visible} className="ExerciseSummary">
      <Card>
        <h1 className="title">Twój Indywidualny Kompas Rozwoju (IKR)</h1>
        {summary.map((questionInfo, index) => (
          <ScaleSummaryComponent key={index} {...questionInfo} />
        ))}
      </Card>
      <Card className="email-card">
        <h2 className="email-label">Podaj swój adres e-mail, aby otrzymać na niego swój IKR</h2>
        <input
          name="email"
          type="email"
          value={email}
          onChange={(e) => {
            setError("shown", false)
            setEmail(e.target.value)
          }}
        />
      </Card>
      <AnimatedElement visible={error.shown}>
        <Card className="error-card" color={Card.COLORS.FAILURE}>
          <h2>{error.message}</h2>
        </Card>
      </AnimatedElement>
      <Button big disabled={sending} onClick={innerOnNext}>
        {sending ? "Wysyłam IKR" : "Zakończ badanie"}
      </Button>
    </AnimatedElement>
  )
}

export const SzymonVotingExerciseAnswers = ({
  answer,
  description = FREQUENCY_DESCRIPTION,
  selectedId,
  selectAnswer$,
}) => {
  return (
    <div className={`SzymonVotingExerciseAnswers VotingExercise-Answers count-6`}>
      {[6, 5, 4, 3, 2, 1].map((answerId) => {
        const ImageComponent = IMAGES[answerId]

        return (
          <div className="answer with-descriptions" key={answerId}>
            <Button
              className={classNames("voting-smiley", `vote-${answerId}-6`, {
                selected: selectedId === answerId,
              })}
              onClick={selectAnswer$(answerId)}
            >
              <ImageComponent />
            </Button>
            <div className="description" onClick={selectAnswer$(answerId)}>
              {description[answerId]}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default withTranslation(["common"])(SzymonVotingExercise)
