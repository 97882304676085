import { Howl } from "howler"

import importFromContext from "../ImportFromDirectory"
import { isArray } from "lodash"

const MP3Files = importFromContext(require.context("./mp3", false, /\.(mp3)$/))

export class Sound {
  constructor(sources) {
    if (!isArray(sources)) sources = [sources]

    this.sound = new Howl({
      src: sources,
    })
  }

  play() {
    this.sound.play(undefined, undefined)
  }
}

export default class Sounds {
  static error = new Sound([MP3Files["error.mp3"].default])
  static success = new Sound([MP3Files["success.mp3"].default])
  static click = new Sound([MP3Files["click.mp3"].default])

  static short_bell = new Sound([MP3Files["short_bell.mp3"].default])
  static long_bell = new Sound([MP3Files["long_bell.mp3"].default])

  static time_out = new Sound([MP3Files["time_out.mp3"].default])
}
