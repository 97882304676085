export const getDragPositionFromEvent = (event) => {
  let element = event
  if (event.changedTouches && event.changedTouches.length > 0) {
    element = event.changedTouches[0]
  } else if (event.touches && event.touches.length > 0) {
    element = event.touches[0]
  }

  return [element.clientX, element.clientY]
}
