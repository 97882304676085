import React, { useState } from "react"
import Draggable from "react-draggable"
import clsx from "clsx"

import Card from "components/Card/Card"

import "./PackageAnswers.scss"
import ReactMarkdown from "react-markdown"

const PATTERNS = [
  require("images/patterns/6.png"),
  require("images/patterns/8.png"),
  require("images/patterns/10.png"),
  require("images/patterns/23.png"),
  require("images/patterns/35.png"),
  require("images/patterns/43.png"),
  require("images/patterns/44.png"),
]

export default function PackageAnswers({ answers, setDraggedPosition, disabled }) {
  const [current, setCurrent] = useState(null)

  const answerDragged$ = (answer, isDragging) => (event) => {
    setCurrent(answer)

    let positionedElement = event
    if (event.changedTouches && event.changedTouches.length > 0) {
      positionedElement = event.changedTouches[0]
    } else if (event.touches && event.touches.length > 0) {
      positionedElement = event.touches[0]
    }

    const position = [positionedElement.clientX, positionedElement.clientY]

    setDraggedPosition(position, answer, isDragging)
  }

  return (
    <div className="PackageAnswers">
      {answers.map((answer, index) => {
        return (
          <Draggable
            handle=".answer"
            position={{ x: 0, y: 0 }}
            onDrag={answerDragged$(answer, true)}
            onStop={answerDragged$(answer, false)}
            disabled={!answer.active || disabled}
            key={index}
          >
            <div className={clsx({ active: current === answer })}>
              <Card className={clsx("answer", { disabled })} isOpaque={!answer.active} isDraggable>
                <div
                  className="background"
                  style={{
                    backgroundImage: `url("${PATTERNS[index % PATTERNS.length].default}")`,
                  }}
                />
                <ReactMarkdown source={answer.content} />
              </Card>
            </div>
          </Draggable>
        )
      })}
    </div>
  )
}
