import React, { useCallback, useMemo } from "react"

import "./TruckExercise.scss"
import { ANSWERS_STATES as AS } from "../../@exercises/Exercise"
import Element from "components/Element"
import { Truck } from "./subcomponents/Truck"
import Belt from "./subcomponents/Belt"
import { useMap } from "react-use"

const TruckExercise = ({ state, inAnswersState, answers, answerChosen, resetTimestamp }) => {
  const [status, { setAll, reset }] = useMap({ answer: null, dragging: false, position: [0, 0] })
  const { answered, all } = useMemo(() => {
    let answered, all
    answered = answers.filter((answer) => answer.correct && answer.answered).length
    all = answers.filter((answer) => answer.correct).length
    return { answered, all }
  }, [answers])

  const onDragging = (x, y, answer) => {
    setAll({ answer, dragging: true, position: [x, y] })
  }
  const onDragged = (x, y, answer) => {
    setAll({ answer, dragging: false, position: [x, y] })
  }
  const onAnswerChosen = useCallback(
    (answer) => {
      reset()
      answerChosen(answer)
    },
    [reset, answerChosen]
  )

  return (
    <Element active={inAnswersState([AS.APPEARING, AS.ACTIVE, AS.INACTIVE])}>
      <Belt
        answers={answers}
        onDragging={onDragging}
        onDragged={onDragged}
        answeredCount={answered}
        allCount={all}
      />
      <Truck
        isDragging={status.dragging}
        draggedAnswer={status.answer}
        draggedPosition={status.position}
        onAnswerChosen={onAnswerChosen}
        isDrivingOff={inAnswersState([AS.DISAPPEARING])}
      />
    </Element>
  )
}

export default TruckExercise
