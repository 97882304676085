import React from "react"
import classNames from "classnames"

import ExerciseComponent, { DEFAULT_STATES } from "base/ExerciseComponent"
import AnimatedElement from "components/AnimatedElement/AnimatedElement"
import InstructionCard from "components/InstructionCard/InstructionCard"
import Button from "components/Button/Button"

import "./OpenQuestionExercise.scss"
import ChosenAnswerStatsModule from "../../@exercises/modules/stats/ChosenAnswerStatsModule"
import { withTranslation } from "react-i18next"

const STATE = {
  STARTED: DEFAULT_STATES.STARTING,
  FINISHED: DEFAULT_STATES.FINISHED,
}

class OpenQuestionExercise extends ExerciseComponent {
  hasSelections
  canSkip

  constructor(props) {
    super(props)
    const question = props.questions[0]
    const { hasSelections, canSkip } = props.parameters
    this.hasSelections = hasSelections ?? true
    this.canSkip = canSkip ?? false

    this.state = {
      ...this.state,
      question,
      answers: question.answers,
      selectedId: undefined,
      selectedAnswer: undefined,

      value: "",
    }

    if (question.answers.length === 1) {
      this.state.selectedId = question.answers[0].id
    }
  }

  usedModules(questions, parameters) {
    return [
      new ChosenAnswerStatsModule(
        {
          resetTimestampInStates: [STATE.STARTED],
        },
        questions,
        parameters
      ),
    ]
  }

  componentDidMount() {
    this._questionAppeared(this.props.questions[0])
  }

  render() {
    const { t } = this.props
    const { question, answers, selectedId, value } = this.state

    const answerComponents = answers.map((answer, index) => {
      const isSelected = answer.id === selectedId

      return (
        <Button
          key={index}
          onClick={this.selectAction(answer.id, answer)}
          className={classNames({ selected: isSelected })}
          disabled={isSelected}
        >
          {answer.content}
        </Button>
      )
    })

    return (
      <AnimatedElement visible={!this.inState(STATE.FINISHED)} className="OpenQuestionExercise">
        <InstructionCard mainText={question.content} />
        {this.hasSelections && (
          <div className="answers-container">{answers.length > 1 && answerComponents}</div>
        )}
        <textarea
          value={value}
          onChange={this.textChanged}
          placeholder={t("enter_answer", { ns: "exercises/open_question" })}
        />
        <Button big onClick={this.innerFinish} disabled={!this.canSend()}>
          {this.canSkip && value.length === 0 ? t("skip") : t("send")}
        </Button>
      </AnimatedElement>
    )
  }

  textChanged = (event) => {
    this.setState({
      value: event.target.value,
    })
  }

  selectAction = (id, answer) => () => {
    this.setState({
      selectedId: id,
      selectedAnswer: answer,
    })
  }

  canSend = () => {
    return (
      (this.state.value.length > 10 || this.canSkip) &&
      (this.state.selectedId || !this.hasSelections)
    )
  }

  innerFinish = () => {
    super._answerChosen(this.state.selectedAnswer ?? this.state.answers[0], {
      chosenAnswerOther: {
        answer: this.state.value,
      },
    })

    this.setCurrentState(STATE.FINISHED, this.onFinish, 1000)
  }

  onFinish = () => {
    // let chosenAnswerData = {
    //   id: this.state.selectedId ?? this.state.answers[0],
    //   data: {
    //     answer: this.state.value,
    //   },
    // }
    //
    // this.props.onFinish({
    //   other: {
    //     chosenAnswerData,
    //   },
    // })
    this.finish(true, {})
  }
}

export default withTranslation(["common", "exercises/open_question"])(OpenQuestionExercise)
