import React from "react"
import imgVertical from "images/exercises/distribution/exercise-vertical.jpg"
import imgHorizontal from "images/exercises/distribution/exercise-horizontal.jpg"

import "./DistributionExerciseInstructions.scss"
import ExerciseInstructions from "../../../base/subcomponents"
import { range } from "lodash"

const DistributionExerciseInstructions = ({
  t,
  parameters: {
    instructions: { name, content, scale },
  },
  onFinish,
}) => {
  return (
    <ExerciseInstructions
      className="DistributionExerciseInstructions"
      name={name}
      imageHorizontal={imgHorizontal}
      imageVertical={imgVertical}
      onFinish={onFinish}
    >
      <p>{content}</p>
      <p className="scales-header">{t("scale")}</p>
      <div className="scales">
        {range(1, 6).map((index) => (
          <div key={index} className={`s${index} scale`}>
            <p className="number">{index}</p>
            <p className="description">{scale[index]}</p>
          </div>
        ))}
      </div>
    </ExerciseInstructions>
  )
}

export default DistributionExerciseInstructions
