import React, { useRef, useState } from "react"
import clsx from "clsx"
import PlayArea from "../../lib/PlayArea"
import SprintArea from "../../app/subcomponents/SprintArea"
import CONFIG from "../../config"
import { SprintCookieConsent } from "../../app/Sprint"
import axios from "axios"
import { useEffectOnce } from "react-use"
import Card from "../Card/Card"

import "./ProjectResults.scss"
import { useTranslation } from "react-i18next"

const CHECKED_INSTANCE = 75

export default function ProjectResults() {
  const { t } = useTranslation("common")
  const [percentile, setPercentile] = useState(-1)
  const tokenRef = useRef(window.localStorage.getItem("token"))
  useEffectOnce(() => {
    if (tokenRef.current) {
      axios({
        method: "POST",
        url: CONFIG.SERVER_ADDRESS + `result/percentile/${CHECKED_INSTANCE}`,
        data: {
          token: tokenRef.current,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((result) => {
          setPercentile(result.data["percentile"])
        })
        .catch((error) => {
          setPercentile(-1)
        })
    }
  })

  let text = <h1>{t("loading")}...</h1>

  if (percentile >= 89) {
    text = (
      <div>
        <h1>{t("project_results.congratulations")}!</h1>
        <p>{t("project_results.hi_description", { percentile })}</p>
        <p>{t("project_results.hi_description_mail", { mail: "paulina.nycz@canpack.com" })}</p>
        <p>{t("project_results.hi_description_regards")}</p>
      </div>
    )
  } else if (percentile >= 50) {
    text = (
      <div>
        <h1>{t("project_results.great")}!</h1>
        <p>{t("project_results.med_description", { percentile })}</p>
      </div>
    )
  } else if (percentile >= 0) {
    text = (
      <div>
        <h1>{t("project_results.summary")}</h1>
        <p>{t("project_results.low_description", { percentile })}</p>
      </div>
    )
  } else {
    text = (
      <div>
        <h1>{t("project_results.no_results")}</h1>
      </div>
    )
  }

  return (
    <div
      className={clsx("Sprint", {
        vertical: PlayArea.isVertical(),
        horizontal: !PlayArea.isVertical(),
      })}
    >
      <SprintArea slug="project-results" className={clsx("game", "scrollable")}>
        <Card className="ProjectResults">{text}</Card>
      </SprintArea>
      {CONFIG.SHOW_CONSENT && <SprintCookieConsent />}
    </div>
  )
}
