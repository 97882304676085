import React from "react"
import imgVertical from "images/exercises/values/exercise-vertical.jpg"
import imgHorizontal from "images/exercises/values/exercise-horizontal.jpg"

import "./ValuesExerciseInstructions.scss"
import ExerciseInstructions from "../../../base/subcomponents"
import ReactMarkdown from "react-markdown"

const ValuesExerciseInstructions = ({
  t,
  parameters: {
    instructions: { content },
  },
  onFinish,
}) => {
  return (
    <ExerciseInstructions
      className="ValuesExerciseInstructions"
      name={t("name")}
      imageHorizontal={imgHorizontal}
      imageVertical={imgVertical}
      onFinish={onFinish}
    >
      <ReactMarkdown source={content} />
    </ExerciseInstructions>
  )
}

export default ValuesExerciseInstructions
