import AnimatedElement from "../AnimatedElement/AnimatedElement"
import React from "react"

export const ANIMATIONS = AnimatedElement.AnimationTypes

const Element = ({ className, active, animation, children, ...other }) => {
  // TODO change to functional component
  return (
    <AnimatedElement className={className} visible={active} animation={animation} {...other}>
      {children}
    </AnimatedElement>
  )
}

export default Element
